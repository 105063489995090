/* global tw */
import React from 'react';
import styled from 'react-emotion';
import '../styles/global';

const Content = styled.div`
  ${tw('p-6 md:p-12 lg:p-24 justify-center items-center flex z-50')};
`;

const Hero = styled.div`
  ${tw('w-full xl:w-3/4')};
`;

const BigTitle = styled.h1`
  ${tw('text-4xl lg:text-6xl font-serif text-white mb-6 tracking-wide')};
  text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15);
`;

const Subtitle = styled.p`
  ${tw('text-2xl lg:text-4xl font-sans text-white mt-8 xxl:w-3/4')};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`;

export default () => (
  <Content>
    <Hero>
      <BigTitle>Thank you!</BigTitle>
      <Subtitle>Hit back on your browser to return.</Subtitle>
    </Hero>
  </Content>
);